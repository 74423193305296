const columns = Object.freeze([
  {
    key: 'full_name',
    label: 'Сотрудник',
    thClass: 'w-25 text-center align-middle',
    tdClass: 'text-center align-middle',
    sortable: true,
  },
  {
    key: 'role',
    label: 'Роль',
    thClass: 'w-15 text-center align-middle',
    tdClass: 'text-center align-middle',
  },
  {
    key: 'city',
    label: 'Город',
    thClass: 'w-15 text-center align-middle',
    tdClass: 'text-center align-middle',
  },
  {
    key: 'phone',
    label: 'Номер телефона',
    thClass: 'w-15 text-center align-middle',
    tdClass: 'text-center align-middle',
  },
  {
    key: 'created_at',
    label: 'Дата регистрации',
    thClass: 'w-15 text-center align-middle',
    tdClass: 'text-center align-middle',
    sortable: true,
  },
  {
    key: 'birth_date',
    label: 'Дата рождения',
    thClass: 'w-15 text-center align-middle',
    tdClass: 'text-center align-middle',
    sortable: true,
  },
  {
    key: 'actions',
    thClass: 'text-center align-middle',
    tdClass: 'text-center align-middle',
    label: '',
  },
])

const colors = Object.freeze([
  {
    value: '#1E98FF',
    text: 'Голубой',
    preset: 'islands#blueIcon',
  },
  {
    value: '#ED4543',
    text: 'Красный',
    preset: 'islands#redIcon',
  },
  {
    value: '#E6761B',
    text: 'Темно-оранжевый',
    preset: 'islands#darkOrangeIcon',
  },
  {
    value: '#0E4779',
    text: 'Темно-синий',
    preset: 'islands#nightIcon',
  },
  {
    value: '#177BC9',
    text: 'Темно-голубой',
    preset: 'islands#darkBlueIcon',
  },
  {
    value: '#F371D1',
    text: 'Розовый',
    preset: 'islands#pinkIcon',
  },
  // {
  //   value: '#B3B3B3',
  //   text: 'Серый',
  //   preset: 'islands#grayIcon',
  // },
  {
    value: '#793D0E',
    text: 'Коричневый',
    preset: 'islands#brownIcon',
  },
  {
    value: '#1BAD03',
    text: 'Темно-зеленый',
    preset: 'islands#darkGreenIcon',
  },
  {
    value: '#B51EFF',
    text: 'Фиолетовый',
    preset: 'islands#violetIcon',
  },
  {
    value: '#595959',
    text: 'Черный',
    preset: 'islands#blackIcon',
  },
  {
    value: '#FFD21E',
    text: 'Желтый',
    preset: 'islands#yellowIcon',
  },
  {
    value: '#56DB40',
    text: 'Зеленый',
    preset: 'islands#greenIcon',
  },
  {
    value: '#FF931E',
    text: 'Оранжевый',
    preset: 'islands#orangeIcon',
  },
  {
    value: '#82CDFF',
    text: 'Светло-голубой',
    preset: 'islands#lightBlueIcon',
  },
  {
    value: '#97A100',
    text: 'Оливковый',
    preset: 'islands#oliveIcon',
  },
])

const presets = {
  '#1E98FF': 'islands#blueIcon',
  '#ED4543': 'islands#redIcon',
  '#E6761B': 'islands#darkOrangeIcon',
  '#0E4779': 'islands#nightIcon',
  '#177BC9': 'islands#darkBlueIcon',
  '#F371D1': 'islands#pinkIcon',
  '#B3B3B3': 'islands#grayIcon', // Reserved
  '#793D0E': 'islands#brownIcon',
  '#1BAD03': 'islands#darkGreenIcon',
  '#B51EFF': 'islands#violetIcon',
  '#595959': 'islands#blackIcon',
  '#FFD21E': 'islands#yellowIcon',
  '#56DB40': 'islands#greenIcon',
  '#FF931E': 'islands#orangeIcon',
  '#82CDFF': 'islands#lightBlueIcon',
  '#97A100': 'islands#oliveIcon',
}

const modal = {
  delete: {
    title: 'Вы действительно хотите удалить сотрудника',
    content: 'Вы действительно хотите удалить сотрудника',
    visible: false,
    okTitle: 'Удалить',
  },
  image: {
    title: 'Фото композиции',
    visible: false,
    okTitle: 'Закрыть',
  },
  status: {
    title: 'Изменить статус',
    visible: false,
    okTitle: 'Изменить',
  },
}

export { columns, modal, colors, presets }
