var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('table-header',_vm._b({on:{"create":_vm.onCreate,"update":_vm.onUpdateFilter,"reset":_vm.onResetFilter},scopedSlots:_vm._u([{key:"filters",fn:function(){return [_c('b-col',{staticClass:"mb-1",attrs:{"lg":"2","md":"6","sm":"12"}},[_c('common-select',_vm._b({on:{"change":_vm.onUpdateFilter}},'common-select',{
            name: 'city_id',
            value: _vm.city_id,
            options: _vm.cities.filter(function (city) { return city.status > 0; }),
            placeholder: 'Город',
            'value-field': 'id',
            'text-field': 'name'
          },false))],1),_c('b-col',{staticClass:"mb-1",attrs:{"lg":"2","md":"6","sm":"12"}},[_c('common-select',_vm._b({on:{"change":_vm.onUpdateFilter}},'common-select',{
            name: 'role',
            value: _vm.role,
            options: _vm.roles,
            placeholder: 'Роль',
            'value-field': 'role',
            'text-field': 'role'
          },false))],1)]},proxy:true}])},'table-header',{
      createTitle: !_vm.hasPermission ? 'Добавить сотрудника' : '',
      currentPage: _vm.page,
      noInitDate: _vm.noInitDate,
      minDate: _vm.minDate,
      maxDate: _vm.maxDate,
    },false)),_c('table-body',_vm._b({on:{"update:page-size":_vm.onPerPageChange,"update:page":_vm.onPageChange}},'table-body',{
      currentPage: _vm.page,
      pageCount: _vm.pageCount,
      pageSize: _vm.page_size,
      itemCount: _vm.itemCount,
    },false),[_c('b-table',_vm._b({staticClass:"position-relative",on:{"sort-changed":_vm.onSort},scopedSlots:_vm._u([{key:"table-busy",fn:function(){return [_c('div',{staticClass:"text-center text-primary my-2"},[_c('b-spinner',{staticClass:"align-middle mr-1"}),_c('span',[_vm._v("Загрузка...")])],1)]},proxy:true},{key:"cell(birth_date)",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(item.birth_date ? _vm.$dayjs(item.birth_date * 1000).format('DD.MM.YYYY') : '')+" ")]}},{key:"cell(created_at)",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$dayjs(item.created_at * 1000).format('DD.MM.YYYY'))+" ")]}},{key:"cell(actions)",fn:function(ref){
    var item = ref.item;
return [_c('table-buttons',_vm._b({on:{"edit":function($event){return _vm.onEdit(Object.assign({}, item))},"delete":function($event){return _vm.onDelete(Object.assign({}, item))}}},'table-buttons',{
            disabled: _vm.hasPermission
          },false))]}},{key:"cell(phone)",fn:function(ref){
          var item = ref.item;
return [_c('a',{attrs:{"href":("tel:+" + (item.phone))}},[_vm._v(_vm._s(_vm._f("VMask")(item.phone,'+#(###) ###-##-##')))])]}}])},'b-table',{
        items: _vm.staffCollection,
        fields: _vm.fields,
        striped: _vm.striped,
        hover: _vm.hover,
        responsive: _vm.responsive,
        showEmpty: _vm.showEmpty,
        emptyText: _vm.emptyText,
        perPage: _vm.page_size,
        busy: _vm.loading,
      },false))],1),_c('common-dialog',_vm._b({on:{"confirm":_vm.onConfirm}},'common-dialog',{
      title: _vm.modal.delete.visible ? _vm.modal.delete.title : '',
      value: _vm.modal.delete.visible
    },false),[_c('p',[_vm._v(" "+_vm._s(((_vm.modal.delete.content) + " " + _vm.full_name + "?"))+" ")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }