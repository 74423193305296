<template>
  <section>
    <table-header
      v-bind="{
        createTitle: !hasPermission ? 'Добавить сотрудника' : '',
        currentPage: page,
        noInitDate,
        minDate,
        maxDate,
      }"
      @create="onCreate"
      @update="onUpdateFilter"
      @reset="onResetFilter"
    >
      <template #filters>
        <b-col
          lg=2
          md=6
          sm=12
          class="mb-1"
        >
          <common-select
            v-bind="{
              name: 'city_id',
              value: city_id,
              options: cities.filter(city => city.status > 0),
              placeholder: 'Город',
              'value-field': 'id',
              'text-field': 'name'
            }"
            @change="onUpdateFilter"
          />
        </b-col>
        <b-col
          lg=2
          md=6
          sm=12
          class="mb-1"
        >
          <common-select
            v-bind="{
              name: 'role',
              value: role,
              options: roles,
              placeholder: 'Роль',
              'value-field': 'role',
              'text-field': 'role'
            }"
            @change="onUpdateFilter"
          />
        </b-col>
      </template>
    </table-header>

    <table-body
      v-bind="{
        currentPage: page,
        pageCount,
        pageSize: page_size,
        itemCount,
      }"
      @update:page-size="onPerPageChange"
      @update:page="onPageChange"
    >
      <b-table
        class="position-relative"
        v-bind="{
          items: staffCollection,
          fields,
          striped,
          hover,
          responsive,
          showEmpty,
          emptyText,
          perPage: page_size,
          busy: loading,
        }"
        @sort-changed="onSort"
      >
        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner class="align-middle mr-1"></b-spinner>
            <span>Загрузка...</span>
          </div>
        </template>

        <template #cell(birth_date)="{ item }">
          {{ item.birth_date ? $dayjs(item.birth_date * 1000).format('DD.MM.YYYY') : '' }}
        </template>

        <template #cell(created_at)="{ item }">
          {{ $dayjs(item.created_at * 1000).format('DD.MM.YYYY') }}
        </template>
        <template #cell(actions)="{ item }">
          <table-buttons
            v-bind="{
              disabled: hasPermission
            }"
            @edit="onEdit({ ...item })"
            @delete="onDelete({ ...item })"
          ></table-buttons>
        </template>
        <template #cell(phone)="{ item }">
          <a :href="`tel:+${item.phone}`">{{ item.phone | VMask('+#(###) ###-##-##') }}</a>
        </template>
      </b-table>
    </table-body>

    <common-dialog
      v-bind="{
        title: modal.delete.visible ? modal.delete.title : '',
        value: modal.delete.visible
      }"
      @confirm="onConfirm"
    >
      <p> {{ `${modal.delete.content} ${full_name}?`}} </p>
    </common-dialog>
  </section>
</template>

<script>
import { AuthNameSpace, RoleCollection } from '@/store/modules/auth/types'
import { mapState, mapActions } from 'vuex'
import { StaffNameSpace, StaffActionTypes } from '@/store/modules/staff/types'
import { CityNameSpace, CityActionTypes } from '@/store/modules/city/types'
import { RoleNameSpace, RoleActionTypes } from '@/store/modules/role/types'
import { throttle } from 'lodash'
import { duration } from '@/config'
import { BTable } from 'bootstrap-vue'

import TableHeader from '@/components/common/table/TableHeader'
import TableBody from '@/components/common/table/TableBody'
import TableButtons from '@/components/common/buttons/TableButtons'

import { modal, columns } from '@/config/staff'

export default {
  name: 'Orders',
  components: {
    BTable,
    TableHeader,
    TableBody,
    TableButtons,
    CommonSelect: () => import('@/components/common/common-select'),
    CommonDialog: () => import('@/components/common/common-dialog'),
  },
  data() {
    return {
      id: '',
      phone: '',
      city_id: null,
      role: null,
      date_from: '',
      created_at: '',
      full_name: '',
      page_size: 10,
      page: 1,
      search: '',
      modal,
      sort_by: {
        sortBy: 'full_name',
        sortDesc: 'asc',
      },
      loading: false,
    }
  },
  props: {
    striped: {
      type: Boolean,
      default() {
        return true
      },
    },
    hover: {
      type: Boolean,
      default() {
        return true
      },
    },
    responsive: {
      type: Boolean,
      default() {
        return true
      },
    },
    showEmpty: {
      type: Boolean,
      default() {
        return true
      },
    },
    emptyText: {
      type: String,
      default() {
        return 'Нет данных'
      },
    },
  },
  computed: {
    ...mapState(AuthNameSpace, {
      userData: 'userData',
    }),
    ...mapState(StaffNameSpace, {
      staffCollection: 'staffCollection',
      staff: 'staff',
      pageCount: 'pageCount',
      itemCount: 'itemCount',
      minDate: 'date_from',
      maxDate: 'date_to',
    }),
    ...mapState(CityNameSpace, {
      cities: 'cities',
      $date_from: 'date_from',
    }),
    ...mapState(RoleNameSpace, {
      roles: 'roles',
    }),
    fields() {
      return columns
    },
    noDateTo() {
      return true
    },
    noInitDate() {
      return true
    },
    hasPermission() {
      return this.userData.role === RoleCollection.florist || this.userData.role === RoleCollection.courier
    },
  },

  async beforeMount() {
    await this.loadCollection()
    await this.loadCityCollection()
    await this.loadRoleCollection()
  },

  methods: {
    ...mapActions(StaffNameSpace, {
      [StaffActionTypes.LoadStaffCollection]: StaffActionTypes.LoadStaffCollection,
      [StaffActionTypes.DeleteStaff]: StaffActionTypes.DeleteStaff,
    }),

    ...mapActions(CityNameSpace, {
      [CityActionTypes.LoadCityCollection]: CityActionTypes.LoadCityCollection,
    }),

    ...mapActions(RoleNameSpace, {
      [RoleActionTypes.LoadRoleCollection]: RoleActionTypes.LoadRoleCollection,
    }),

    loadCollection: throttle(async function() {
      try {
        this.loading = true
        await this[StaffActionTypes.LoadStaffCollection]({ ...this.$data })
      } catch (e) {
        console.error(e)
      } finally {
        this.loading = false
      }
    }, duration),

    loadCityCollection: throttle(async function() {
      await this[CityActionTypes.LoadCityCollection]({ page: 1, page_size: 50 })
    }, duration),

    loadRoleCollection: throttle(async function() {
      await this[RoleActionTypes.LoadRoleCollection]({ page: 1, page_size: 50 })
    }, duration),

    onCreate() {
      this.$router.push(`${this.$route.path}/new`)
    },

    onEdit({ id }) {
      this.$router.push(`${this.$route.path}/${id}`)
    },

    onDelete({ id, full_name }) {
      this.id = id
      this.full_name = full_name

      this.modal.delete.visible = true
    },

    async onConfirm({ confirmed }) {
      if (confirmed) {
        try {
          if (this.modal.delete.visible) {
            await this[StaffActionTypes.DeleteStaff]({ ...this.$data })

            if (this.staffCollection.length === 1 && this.page !== 1) this.page -= 1

            await this.loadCollection()
          }
        } catch {
          for (const type in this.modal) {
            if (this.modal[type].visible) {
              this.modal[type].visible = false
            }
          }
        }
      }

      for (const type in this.modal) {
        if (this.modal[type].visible) {
          this.modal[type].visible = false
        }
      }
    },

    async onUpdateFilter(context) {
      Object.assign(this.$data, context)
      this.page = 1
      await this.loadCollection()
    },

    async onResetFilter(context) {
      Object.assign(this.$data, context)

      this.city_id = null
      this.role = null

      await this.loadCollection()
    },

    async onPerPageChange(count) {
      this.page = 1
      this.page_size = parseInt(count, 10)

      await this.loadCollection()
    },

    async onPageChange(count) {
      this.page = parseInt(count, 10)
      await this.loadCollection()
    },

    async onSort({ sortBy, sortDesc }) {
      if (!sortBy) return

      if (sortBy !== this.sort_by.sortBy) {
        this.sort_by.sortBy = sortBy
      }

      this.sort_by.sortDesc = sortDesc ? 'desc' : 'asc'

      await this.loadCollection()
    },
  },
}
</script>

<style scoped>
</style>
